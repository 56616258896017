<template>
    <div>
        <h3>{{message}}</h3>
        <pre>{{code}}</pre>
        <input type="text" v-model="number" v-if="askNumber" placeholder="Number" />
        <input type="text" v-model="ping" v-if="askPing" placeholder="Ping" />
        <button v-if="askNumber || askPing" v-on:click="send">Send</button>
    </div>
</template>

<script>
import axios from 'axios';
import Button from './components/button/Button.vue';

export default {
  components: { Button },
    data: ()=>{
        return {
            message: '',
            code: '',
            askNumber: false,
            askPing: false,
            number: '',
            ping: '',
            company: ''
        }
    },
    mounted() {
        if(this.$route.query.sId){
            axios.post('http://localhost:5500/api/mobile/confir-entel-sid')
            .then(function (response) {
                if(response){
                    switch(response.data.code){
                        case 2: 
                            this.message = 'suscripción exitosa'
                            this.code = JSON.stringify(response.data, null ,4)
                        break;
                        case 1: 
                            this.message = 'Falla al realizar suscripcion con el sid de entel'
                            this.code = JSON.stringify(response.data, null ,4)
                        break;
                    }
                }else{
                    alert(-1)
                }
            }).catch(function (error) {
                alert(-1)
            })
        }else{
            this.validIp()
        }
    },
    methods: {
        async send(){
            if(this.askNumber){
                //number
                askNumber = false;
                var data = JSON.stringify({
                "phone": this.number
                });

                var config = {
                method: 'post',
                url: 'http://localhost:5500/api/mobile/enter-number',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
                };

                axios(config)
                .then(function (response) {
                    if(response.data.code == 2){
                        this.message = 'El ping fue enviado - corresponda a compania '+response.data.company;
                        this.company = response.data.company;
                        askPing = true;
                    }else{
                        this.message = 'falla al enviar ping';
                        this.code = JSON.stringify(response.data, null ,4);
                    }
                })
                .catch(function (error) {
                    alert('Catch-phone')
                });
            }else{
                //ping
                askPing = false;
                var data = JSON.stringify({
                    "phone": this.number,
                    "ping":this.ping,
                    "movistar": this.company == 'Movistar'
                });

                var config = {
                method: 'post',
                url: 'http://localhost:5500/api/mobile/confirm-pin',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
                };

                axios(config).then(function (response) {
                    if(response.data.code == 2){
                        this.message = 'suscripción exitosa';
                    }else{
                        this.message = 'falla al realizar la suscripción';
                        this.code = JSON.stringify(response.data, null ,4);
                    }
                })
                .catch(function (error) {
                    alert('Catch-phone')
                });
            }
        },
        async validIp(){
            axios.get('http://localhost:5500/api/mobile/')
            .then(function (response) {
                if(response){
                    switch(response.data.code){
                        case 2: 
                            window.location.href = response.data.url;
                        break;
                        case 1: 
                            this.encriptMovistar();
                        break;
                    }
                }else{
                    alert(-1)
                }
            }).catch(function (error) {
                alert(-1)
            })
        },
        async encriptMovistar() {
            var qs = require('qs');
            var data = qs.stringify({'text': '{"REQUEST":[{"IDPRODUCT":241,"IDOPERATOR":730100}],"OUTPUTFORMAT":"json"}'});
            var config = {
                method: 'post',
                url: 'https://suscripciones.multinetlabs.com/suscripciones-ws/rest/mood/encode',
                headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
                },
                data : data
            };
            axios(config).then(function (response) {
                var token1 = response.data.toString();

                //! seguimos por encriptar el siguiente token
                var axios = require('axios');
                data = qs.stringify({
                'idProduct': '241' 
                });
                config = {
                method: 'post',
                url: 'https://suscripciones.multinetlabs.com/suscripciones-ws/rest/mood/gen',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                data : data
                };

                axios(config)
                .then(function (response2) {
                    var token2 = response2.data.toString();
                    var data = JSON.stringify({
                        "ttkn": token2,
                        "data": token1
                    });
                    var config = {
                    method: 'post',
                    url: 'http://localhost:5500/api/mobile/susc-movistar',
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data : data
                    };
                    axios(config).then(function (response) {
                        if(response.data.code = 2){
                            this.message = 'Suscripción movistar finalizada';
                            this.code = JSON.stringify(response.data, null ,4);
                        }else{
                            askNumber = true;
                        }
                    })
                    .catch(function (error) {
                        alert('Catch3')
                    });
                })
                .catch(function (error) {
                    alert('Catch2')
                });
            }).catch(function (error) {
                alert('Catch1')
            });
        }
    }
}
</script>

<style>

</style>